<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1><i class="mdi mdi-credit-card"
                                        style="transform: scale(1.7);"></i> RIBs</h1>
                        <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page allows you to manage RIBs. </label>
                        <div v-if="addingRIB">
                            <button @click="addingRIB = false" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                            <form @submit.prevent="preventsubmit">
                                <div class="form-row">
                                    <b-form-group label="RIB" label-for="RIB">
                                        <b-form-input type="text" id="RIB" v-model="RIB.RIB"
                                           ></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-10" label="Sold" label-for="input-sm">
                                        <b-form-input type="number" id="input-sm" v-model="RIB.Sold"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <label class="d-block">Agency</label>
                                <multiselect v-model="RIB.Agency" :options="Agencies"></multiselect>
                                <div class="mt-5 mb-5 text-center">
                                    <button type="submit" @click="handleSumbit"
                                        class="btn w-25 btn-outline-primary btn-bordered-primary">Add</button>
                                </div>
                            </form>
                        </div>
                        <div v-else>
                            <p class="text-muted font-13 mb-4"></p>
                            <div class="row mb-md-4">
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <label class="d-inline-flex align-items-center">New RIB</label>
                                    <button type="button" class="btn btn-primary ml-2" @click="addingRIB = true"><i
                                            class="mdi mdi-credit-card-plus-outline"></i></button>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive table-striped mb-0">
                                <b-table style="padding: .5rem;" :items="existingRibs" :fields="fields" responsive="sm"
                                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered">
                                    <template v-slot:cell(edit)="row">
                                        <b-button @click="openEditModal(row.item)" variant="primary" v-b-tooltip.hover title="Edit RIB">
                                        <i class="mdi mdi-circle-edit-outline" style="transform: scale(1.7);"></i>
                                        </b-button>

                                        <b-button v-if="row.item.Status == 'Active'" class="ml-2" @click="disableRIB(row.item)" variant="danger" v-b-tooltip.hover title="Disable RIB">
                                        <i class="mdi mdi-cancel" style="transform: scale(1.7);"></i>
                                        </b-button>

                                        <b-button v-if="row.item.Status == 'Disabled'" class="ml-2" @click="enableRIB(row.item)" variant="success" v-b-tooltip.hover title="Enable RIB">
                                        <i class="mdi mdi-checkbox-marked-circle" style="transform: scale(1.7);"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                                <b-modal id="edit-rib-modal" title="Edit RIB" hide-footer>
                                    <b-form @submit.prevent="updateRIB">
                                        <!-- Create your form inputs here, e.g.: -->
                                        <b-form-group label="RIB :">
                                        <b-form-input v-model="selectedRIB.RIB"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Sold :">
                                        <b-form-input v-model="selectedRIB.Sold"></b-form-input>
                                        </b-form-group>
                                        <!-- Add more form inputs as needed -->

                                        <!-- Submit button for the form -->
                                        <b-button type="submit" variant="primary">Save Changes</b-button>
                                    </b-form>
                                </b-modal>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import Multiselect from "vue-multiselect";

export default {
    data() {
        return {
            addingRIB: false,
            Agencies: [],
            existingRibs: [],
            RIB: {
                Societe: localStorage.getItem("societe").slice(1, -1),
                Agency: "",
                RIB: "",
                Sold: "",
            },
            selectedRIB: {RIB: '',
      Sold: 0},
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                {
                    key: "RIB",
                    sortable: true,
                },
                {
                    key: "Sold",
                    sortable: true,
                },
                {
                    key: "Status",
                    sortable: true,
                },
                {
                    key: "Agency",
                    sortable: true,
                },
                { key: 'edit', label: 'Actions' }
                
            ],
        }
    },
    components: {
        Layout,
        Multiselect,
    },
    computed: {
        rows() {
            return this.existingRibs.length;
        },
    },
    methods: {
        async enableRIB(item) {
            this.selectedRIB = { ...item };
         await axios.put(`https://finex.4help.tn/api/v1/enablerib/${this.selectedRIB.RIB}`)
         .then(
             (res) => {
                 if (res.status === 200) {
                     this.getexistingRibs()
                     swal({ title: 'Success', text: `Successfully enabled ${this.selectedRIB.RIB}!`, icon: 'success', });

                 }else
                 {
                     swal({ title: 'Error', text: `Failed to enable ${this.selectedRIB.RIB}!`, icon: 'error', });
                 }
             }
         )
        },
        async disableRIB(item) {
            this.selectedRIB = { ...item };
         await axios.put(`https://finex.4help.tn/api/v1/disablerib/${this.selectedRIB.RIB}`)
         .then(
             (res) => {
                 if (res.status === 200) {
                     this.getexistingRibs()
                     swal({ title: 'Success', text: `Successfully disabled ${this.selectedRIB.RIB}!`, icon: 'success', });
                 }else
                 {
                     swal({ title: 'Error', text: `Failed to disable ${this.selectedRIB.RIB}!`, icon: 'error', });
                 }
             }
         )
        },
        async updateRIB() {
    // Here you would handle the logic to update the RIB
    // This could be an API call or a local state update
    console.log('Updated RIB:', this.selectedRIB);
    // Close the modal after updating
    await axios.put(`https://finex.4help.tn/api/v1/editrib/${this.selectedRIB.RIB}`, this.selectedRIB).then(res => { swal({ title: 'Success', text: `Successfully edited ${this.selectedRIB.RIB}!`, icon: 'success', });
    this.getexistingRibs()})
    this.$bvModal.hide('edit-rib-modal');
  },
        
        openEditModal(rib) {
    // Set the selected RIB data to a data property if needed, e.g.:
    // this.selectedRIB = rib;
    this.selectedRIB = { ...rib }; // Clone the rib data to avoid direct mutation
    this.$bvModal.show('edit-rib-modal');
  },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getexistingRibs() {
            this.existingRibs = await axios.get(`https://finex.4help.tn/api/v1/getribs/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data })
            console.log(this.existingRibs)
        },
        async getBanks() {
            this.Agencies = await axios.get(`https://finex.4help.tn/api/v1/getbankagencies/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data.map(obj => obj["Agency Name"]) })
        },
        preventsubmit(e) {
            e.preventDefault()
        },
        async handleSumbit() {
            try {
                await axios.post('https://finex.4help.tn/api/v1/addrib', this.RIB).then(async (res) => {
                    if (res.status === 200) {
                        swal({
                            title: 'Success',
                            text: `Successfully added ${this.RIB.RIB}!`,
                            icon: 'success',
                        });
                        this.addingRIB = false
                        await this.getexistingRibs()
                        this.currentPage = 1
                        this.RIB = {
                            Societe:localStorage.getItem("societe").slice(1, -1),
                            Agency: "",
                            RIB: "",
                            Sold: "",
                        }

                    }
                })
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    swal({
                        title: 'Conflict',
                        text: `RIB: ${this.RIB.RIB} already exists, please try a different one.`,
                        icon: 'error',
                    });
                }
                else {
                    swal({
                        title: 'Error',
                        text: `Server error.`,
                        icon: 'error',
                    });
                }
            }
        }
    },
    async mounted() {
        await this.getBanks()
        await this.getexistingRibs()
    },

}
</script>

<style scoped>


.card {
    min-height: 100%;
    margin-bottom: 0px !important;
}

h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f2f2f2 !important;
    font-weight: bold;
    color: #333;
}


tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}

tr:hover {
    background-color: #e0e0e0 !important;
    cursor: pointer;
    transition: background-color 0.3s;
}</style>